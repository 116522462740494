import React, {Fragment} from "react";
import './RandomGrid.scss'
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import classNames from "classnames";
import Arrow from "../../assets/images/pijlNav.svg";

export function GridItem({gridArea, data, lastRow, firstColumn}) {
    function getLinkTarget(data) {
        if (data.urlIsExternal === true) {
            return ({target: "_blank"});
        }
        return {};
    }

    return (
        <Fragment>
            {!!data.url && <Link className={"anchor-no-decoration"} to={data.url} {...getLinkTarget(data)} style={{gridArea: gridArea}}>
                <Card className={classNames("gridItem", "border-end-0", "h-100", {"border-bottom-0": !lastRow, "border-start-0": firstColumn})}>
                    <Card.Title className='m-3'>
                        {data.title}
                    </Card.Title>
                    <Card.Footer className={"mt-auto border-0 bg-white"}>
                        <img src={Arrow} style={{width: "50px", marginBottom: "20px"}} alt={"Arrow"}/>
                    </Card.Footer>
                </Card>
            </Link>}
            {!data.url &&
                <Card onClick={() => data.onClickHandler()} className={classNames("gridItem", "border-end-0", "h-100", "clickable", {
                    "border-bottom-0": !lastRow,
                    "border-start-0": firstColumn
                })}
                      style={{gridArea: gridArea}}>
                    <Card.Title className='m-3'>
                        {data.title}
                    </Card.Title>
                    <Card.Footer className={"mt-auto border-0 bg-white"}>
                        <img src={Arrow} style={{width: "50px", marginBottom: "20px"}} alt={"Arrow"}/>
                    </Card.Footer>
                </Card>
            }
        </Fragment>
    )
}
